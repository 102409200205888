<template>
  <div id="COMPANY_BADGE_GROUP_MODAL" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" footer-class="footerClass" title="Edit Assigned Company"
      no-close-on-backdrop  content-class="shadow"
      @hide="modalCancel"
      scrollable
    >
      <b-form-group>
        <label class="mr-1">{{ $t(`project.field.companies`) }}</label>
        <button class="btn-action" @click="companySelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/></button>
      
        <BadgeGroup v-model="companies">
          <template v-slot:default="{ item, index }">
            <Badge @badgeRemove="companyBadgeRemove(index)" @badgeClick="canView('COMPANY') ? companyBadgeClick(item.uuId) : ''"
              :text="`${item.name}`" 
              variant="primary"
              :pillable="!!item.pillable" :key="index" />
            </template>
        </BadgeGroup>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('badgeGroupError') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <!-- company selector -->
    <CompanySelectorModalForAdmin v-if="companySelectorShow"
      :show.sync="companySelectorShow" 
      :preselected="companyEditId"
      nonAdmin
      @cancel="companySelectorCancel"
      @ok="companySelectorOk"
    />
  </div>
</template>

<script>
import { objectClone } from '@/helpers'
import { fieldValidateUtil } from '@/script/helper-field-validate'

export default {
  name: 'CompanyBadgeGroupModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    CompanySelectorModalForAdmin: () => import('@/components/modal/CompanySelectorModalForAdmin')
  },
  props: {
    show: { type: Boolean, required: true }
    , companyList: { type: Array, default: () => [] }
    , isOptional: { type: Boolean, default: false }
  },
  data() {
    return {
      modalShow: false
      , companySelectorShow: false
      , companies: []
      , companyEditId: null
    }
  },
  created() {
    this.fieldValidateUtil = fieldValidateUtil
    this.modifiedList = []
  },
  beforeMount() {
    this.companies.splice(0, this.companies.length, ...this.companyList)
    this.modalShow = true
  },
  mounted() {

  },
  beforeDestroy() {
    this.modifiedList = null
    this.fieldValidateUtil = null
  },
  watch: {
    show(newValue) {
      if (newValue != this.modalShow) {
        this.errors.clear()
        this.modifiedList.splice(0, this.modifiedList.length)
        this.modalShow = newValue
        if (newValue) {
          this.companies.splice(0, this.companies.length, ...this.companyList)
        }
      }
    }
  },
  computed: {
    showError() {
      return fieldValidateUtil.hasError(this.errors, 'badgeGroupError');
    },
  },
  methods: {
    modalCancel() {
      //listen to hide instead of hidden event, this.modifiedList has not been reset yet in hide event
      this.$emit('cancel', { modifiedList: objectClone(this.modifiedList) })
    }
    , companySelectorCancel({ modifiedList=[] }={}) {
      if (modifiedList.length > 0) {
        this.modifiedList.push(...modifiedList)
      }
      this.companySelectorShow = false
    }
    , companyBadgeRemove: function(index) {
      this.companies.splice(index,1)
    }
    , companyBadgeClick(id) {
      this.companyEditId = id
      this.companySelectorShow = true;
    }
    , companySelectorToggle() {
      this.companyEditId = null
      this.companySelectorShow = true
    }
    , companySelectorOk({ details }) {
      this.errors.clear()
      const companies = JSON.parse(JSON.stringify(this.companies));
      
      if (details.length > 0) {
        const newCompaniesToReplacePreselected = [];
        for (const r of details) {
          const foundIndex = companies.findIndex(i => i.uuId == r.uuId);
          if (foundIndex > -1) {
            const company = companies[foundIndex];
            company.uuId = r.uuId;
            company.name = r.name;
            newCompaniesToReplacePreselected.push(company);
            companies.splice(foundIndex, 1);
          } else {
            newCompaniesToReplacePreselected.push({ uuId: r.uuId, name: r.name })
          }
        }
        
        if (this.companyEditId != null) {
          const foundIndex = companies.findIndex(i => i.uuId == this.companyEditId);
          if (foundIndex != -1) {
            companies.splice(foundIndex, 1, ...newCompaniesToReplacePreselected);
          } else {
            companies.push(...newCompaniesToReplacePreselected);
          }
        } else {
          companies.push(...newCompaniesToReplacePreselected);
        }
      }  else if (this.companyEditId != null) {
        //When no selection is made, remove the preselected one from the existing companies
        companies.splice(companies.findIndex(j => j.uuId === this.companyEditId), 1);
      }
      this.companies.splice(0, this.companies.length, ...companies);

      this.companyEditId = null;
    }
    , ok() {
      this.errors.clear()
      if (!this.isOptional && this.companies.length < 1) {
        this.errors.add({
          field: 'badgeGroupError',
          msg: this.$i18n.t('error.missing_argument', [this.$t(`project.field.companies`)])
        })
      }

      this.$nextTick(() => {
        if (this.errors.items.length < 1) {
          this.$emit('ok', objectClone(this.companies))
          this.$emit('update:show', false)
        }
      })
    }
  }
}
</script>